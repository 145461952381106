import { React, useEffect, useRef } from "react";
import "./manufacturingprocessinner.scss";
import { hookinner } from "../../assets";
import LocomotiveScroll from "locomotive-scroll";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
import { Link } from "react-router-dom";
import LeftArrowCtaSvg from "../../common/leftarrowCtaSvg";
const imagesLoaded = require("imagesloaded");

const FinalInspection = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);

      scroll.destroy();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          overflow: "hidden",
        }}
      >
        <section id="scrolltop" className="hook_sec1">
          <Link to="/manufacturing-process" className="back_btn_wrapper">
            <div className="back_cta_arrow">
              <LeftArrowCtaSvg />
            </div>
            <button className="back_btn"> Back</button>
          </Link>
          <div className="hook_container">
            <div className="hook_image_wrapper">
              <motion.div
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <img
                  src={hookinner}
                  alt="hook"
                  className="hook_img"
                  data-scroll
                  data-scroll-speed="1"
                />
              </motion.div>
            </div>
            <motion.div
              variants={textVariants("right", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <div className="hook_content">
                <h2 className="hook_heading">Final Inspection</h2>
                <p className="hook_details">
                  After the eddy current testing, hydrostatic testing, and
                  annealing process, conduct a final visual inspection to ensure
                  that there are no new defects introduced during these
                  processes. Records and documents of all inspection results are
                  maintained. This documentation is essential for quality
                  control and compliance with industry standards.
                  <br />
                  <br />
                  It's crucial to follow industry standards and specific
                  project requirements during the inspection process to ensure
                  the copper-nickel pipes meet the necessary quality standards.
                  Additionally, personnel conducting inspections are trained and
                  qualified to perform the required tests.
                </p>
              </div>
            </motion.div>
          </div>
        </section>
        <section
          className="footer_area"
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default FinalInspection;
