import { React, useEffect, useRef } from "react";
import "./manufacturingprocessinner.scss";
import { packagesinner } from "../../assets";
import LocomotiveScroll from "locomotive-scroll";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
import { Link } from "react-router-dom";
import LeftArrowCtaSvg from "../../common/leftarrowCtaSvg";
const imagesLoaded = require("imagesloaded");

const PackagingDispatch = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);

      scroll.destroy();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          overflow: "hidden",
        }}
      >
        <section id="scrolltop" className="package_sec1">
          <Link to="/manufacturing-process" className="back_btn_wrapper">
            <div className="back_cta_arrow">
              <LeftArrowCtaSvg />
            </div>
            <button className="back_btn"> Back</button>
          </Link>
          <div className="package_container">
            <motion.div
              variants={textVariants("right", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
              className="package_content_box"
            >
              <div className="package_content">
                <h2 className="package_heading">Packaging and Dispatch</h2>
                <p className="package_details">
                  Once the inspection is complete, the pipes can be properly
                  packaged for dispatch and shipping. Ensure that the packaging
                  meets transportation standards to prevent any damage during
                  transit.
                </p>
              </div>
            </motion.div>
            <div className="package_image_wrapper">
              <motion.div
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
                className="package_img_box"
              >
                <img
                  src={packagesinner}
                  alt="package"
                  className="package_img"
                  data-scroll
                  data-scroll-speed="1"
                />
              </motion.div>
            </div>
          </div>
        </section>
        <section
          className="footer_area"
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default PackagingDispatch;
